import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import Slider from 'react-slick';
import rehypeRaw from 'rehype-raw';

import 'slick-carousel/slick/slick.css';
import '../common/casestudy.css';
import Container from './Container';
import 'styles/screenReader.css';
import CloudinaryImage from './CloudinaryImage';

export default function CaseStudy({ realworld }) {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const settings = {
    dots: false,
    // infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    // autoplay: true,
    // autoplaySpeed: 2000,
    arrows: false,
    centerMode: true,
    // variableWidth: true,
    // pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="xl:py-[75px] py-[40px] relative overflow-hidden bg-custom-color bg-[#00060F]">
      <Container>
        {realworld?.titleEditor && (
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            className={`text-[#ffffff] font-[800] lg:text-[50px] md:text-[32px] text-[22px] title_hd leading-tight max-w-[1200px] w-full mx-auto md:px-0 px-10 leading-7 ${realworld?.titleEditor && 'xl:mb-[34px] mb-[30px]'}`}
            source={realworld?.titleEditor}
          >
            {realworld?.titleEditor}
          </ReactMarkdown>
        )}

        <Slider
          asNavFor={nav2}
          ref={slider => (sliderRef1 = slider)}
          // fade={true}
          arrows={false}
          className="h-full w-full max-w-[1200px] mx-auto"
          adaptiveHeight={true}
        >
          {realworld?.SolutionDesc?.map(items => (
            <div key={items?.id} className="relative">
              <div
                className={`md:h-[500px] h-auto bg-cover bg-no-repeat bg-center rounded-[5px] flex items-center justify-center relative`}
              >
                {items?.bgImage?.data?.attributes?.url && (
                  <CloudinaryImage
                    backendImgUrl={items?.bgImage?.data?.attributes?.url}
                    alt={items?.bgImage?.data?.attributes?.alternativeText}
                    type="smallimg"
                    layout="fill"
                    objectFit="cover"
                    className="w-full h-full absolute top-0 bottom-0 left-0 right-0"
                  />
                )}
                <div className="absolute top-0 bottom-0 left-0 right-0 bg-[rgba(0,6,15,.85)] z-0"></div>

                <div className={`max-w-[1000px] mx-auto relative z-[1]`}>
                  <div className="md:text-[35px] md:mb-0 mb-2 text-[18px] font-[700] text-[#FFFFFF] line-clamp-2">
                    {items?.title}
                  </div>
                  <div className="md:text-[20px] text-[14px] font-[400] text-[#FFFFFF] line-clamp-2">
                    {items?.description}
                  </div>
                  {items?.btn_link && (
                    <Link
                      href={items?.btn_link}
                      className="mt-[35px] bg-[#FFA030] text-[#000D3A] border border-[#fff] group h-min relative rounded-[4px] overflow-hidden inline-block px-14 py-2 shadow-[10px_20px_40px_0px_#00000033]"
                    >
                      <span className="screen-reader-text">{`${items?.btn_link}`}</span>
                      <span className="bg-[#fff] absolute top-0 left-0 flex w-full h-0 mb-0 transition-all duration-200 ease-out transform translate-y-0 group-hover:h-full opacity-1"></span>
                      <span className="lg:text-[24px] text-[16px] group-hover:text-black flex items-center font-[600] relative">
                        {items?.btn_text}
                      </span>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="casedot">
          <Slider
            asNavFor={nav1}
            ref={slider => (sliderRef2 = slider)}
            {...settings}
            swipeToSlide={true}
            focusOnSelect={true}
            className="md:mt-[-90px] mt-0"
          >
            {realworld?.SolsInfo &&
              realworld?.SolsInfo?.map(data => (
                <div
                  key={data?.id}
                  aria-hidden="true"
                  className={`casedotinner ${data?.title && 'border border-[#FFFFFF] bg-[#14161A]'} rounded-[8px] px-3 py-3`}
                >
                  <h6 className="text-[16px] text-[#FFFFFFB2] md:font-[400] font-[700] mb-1">
                    {data?.title}
                  </h6>
                  <p className="md:text-[14px] text-[12px] text-[#FFFFFFB2] md:font-[300] font-[400] line-clamp-3">
                    {data?.description}
                  </p>
                </div>
              ))}
          </Slider>
        </div>
      </Container>
    </div>
  );
}
